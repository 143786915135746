
<template>
  <el-tabs v-model="tabActiveName" type="border-card" v-loading="isLoading">
    <el-tab-pane label="填报" name="submit" :disabled="!selectThings">
      <div>
        <h3>{{ title }}</h3>
        <div class="borderForm">
          <el-alert :title="timeLimitTips" type="warning" show-icon
            v-show="selectThings && selectThings.timelimit"></el-alert>
          <el-form :model="form" label-width="180px" ref="refForm" :rules="rules" :disabled="true">
            <el-collapse v-model="collapseAcitveName">
              <el-collapse-item name="0" title="省级审核意见">
                <el-form-item label="审核意见">
                  <FileuploadListV1 v-model:files="form.attachList" fileGroup="review" accpet=".pdf" urlKey="filePath"
                    nameKey="fileName">
                  </FileuploadListV1>
                </el-form-item>
              </el-collapse-item>
              <el-collapse-item name="1" title="基本信息">
                <el-form-item label="申请文件标题" prop="applyFileTitle">
                  <el-input v-model="form.applyFileTitle" placeholder="建议包含项目名称"></el-input>
                </el-form-item>
                <el-form-item label="申请文号" class="is-required">
                  <el-space style="vertical-align: top">
                    <el-form-item prop="doc_Type">
                      <el-autocomplete v-model="form.doc_Type" :fetch-suggestions="queryDocTypeSearch" clearable
                        placeholder="批文类型" />

                    <!-- <el-select v-model="form.doc_Type" placeholder="批文类型" filterable allow-create
                        default-first-option>
                        <el-option :label="item.label" :value="item.label" v-for="item in docTypeDic"
                          :key="item.label"></el-option>
                        </el-select> -->
                    </el-form-item>
                    <el-form-item prop="doc_Year">
                      <el-select v-model="form.doc_Year" placeholder="批文年份">
                        <el-option :label="item" :value="item" v-for="item in docYearDic" :key="item"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="doc_Serial_No">
                      <el-input v-model="form.doc_Serial_No" placeholder="批文号"></el-input>
                    </el-form-item>
                    <span>号</span>
                  </el-space>
                </el-form-item>

                <el-row>
                  <el-col :span="12">
                    <el-form-item label="申请单位" prop="applyPerson">
                      <el-input v-model="form.applyPerson" placeholder="申请单位"></el-input> </el-form-item></el-col>
                  <el-col :span="12">
                    <el-form-item label="经办人" prop="doPerson">
                      <el-input v-model="form.doPerson" placeholder="经办人"></el-input> </el-form-item></el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="联系电话" prop="concactPhone">
                      <el-input v-model="form.concactPhone" placeholder="联系电话"></el-input> </el-form-item></el-col>
                  <el-col :span="12"></el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item name="2" title="项目信息">
                <el-form-item label="项目名称" prop="projectName">
                  <el-input v-model="form.projectName" placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="文保单位" prop="heritageUnitName">
                  <el-row style="width: 600px">
                    <el-col :span="18">
                      <el-input v-model="form.heritageUnitName" placeholder="填写或选择文保单位">
                      </el-input></el-col>
                    <el-col :span="6">
                      <SelectUnit :setting="selectUnitSetting" v-model="form.heritageUnitId" @change="selectUnitChange">
                      </SelectUnit>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="文物类型" prop="heritageClass">
                  <el-select v-model="form.heritageClass" placeholder="文物类型">
                    <el-option v-for="item in heritageClassDic" :key="item.value" :label="item.label"
                      :value="parseInt(item.value)"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="地区" prop="provId">
                  <el-space>
                    <el-select v-model="form.provId" placeholder="省份" disabled>
                      <el-option label="山东省" :value="22"></el-option>
                    </el-select>

                    <el-select v-model="form.cityId" placeholder="市级" clearable>
                      <el-option v-for="item in dicCity" :key="item.id" :value="item.id" :label="item.name"></el-option>
                    </el-select>
                  <!-- {{ form }}
                        {{ form.countryId }}-->
                    <el-select v-model="form.countryId" placeholder="区县" clearable>
                      <el-option v-for="item in dicTown" :key="item.id" :value="item.id" :label="item.name"></el-option>
                    </el-select>
                  </el-space>
                </el-form-item>

                <el-form-item label="级别" prop="heritageLevel" v-if="['56005', '56022'].includes(currentFormSetting.key)">
                  <el-radio-group v-model="form.heritageLevel">
                    <el-radio label="1">全国重点文物保护单位</el-radio>
                    <el-radio label="2">省级文物保护单位</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item label="是否革命文物" prop="isRevolution">
                  <el-radio-group v-model="form.isRevolution">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>

                  <!-- <el-switch v-model="form.isRevolution" /> -->
                </el-form-item>
                <el-form-item label="是否世界遗产" prop="isWorldHeritage">
                  <el-radio-group v-model="form.isWorldHeritage">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>

                  <!-- <el-switch v-model="form.isWorldHeritage" /> -->
                </el-form-item>

                <el-form-item label="保护措施方案名称" prop="planName" v-if="currentFormSetting.key == '56005'">
                  <el-input v-model="form.planName" placeholder="保护措施方案名称"></el-input>
                </el-form-item>
                <template v-if="currentFormSetting.key == '56010'">
                  <el-form-item label="必须迁移或拆除文物保护单位的理由" prop="removeUnitReason">
                    <el-input type="textarea" rows="5" v-model="form.removeUnitReason"
                      placeholder="不超过2000个汉字"></el-input>
                  </el-form-item>
                  <el-form-item label="迁移或拆除的文物保护单位的值说明" prop="removeUnitDesc">
                    <el-input type="textarea" rows="5" v-model="form.removeUnitDesc" placeholder="不超过2000个汉字"></el-input>
                  </el-form-item>
                  <el-form-item label="迁移或拆除文物保护单位方案名称" prop="planName">
                    <el-input v-model="form.planName" placeholder="方案名称"></el-input>
                  </el-form-item>
                </template>

                <template v-if="currentFormSetting.isProjectType1">
                  <el-form-item label="工程对象名称" prop="projectType1ObjName">
                    <el-input v-model="form.projectType1ObjName" placeholder="工程对象名称"></el-input>
                  </el-form-item>
                  <el-form-item label="保护工程类型" prop="projectType1">
                    <el-radio-group v-model="form.projectType1">
                      <el-radio :label="item.value" :key="item.value" v-for="item in projectType1Dic">{{ item.label
                      }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="保护措施方案名称" prop="planName">
                    <el-input v-model="form.planName" placeholder="保护措施方案名称"></el-input>
                  </el-form-item>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="建设范围及规模" prop="projectType1Area">
                        <el-input v-model="form.projectType1Area" placeholder="工程保护对象及范围" type="textarea"></el-input>
                      </el-form-item></el-col>
                    <el-col :span="12">
                      <el-form-item label="工程概算（万元）" prop="projectType1Amount">
                        <el-input v-model="form.projectType1Amount" placeholder="工程概算（万元）"></el-input>
                      </el-form-item></el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="经费来源" prop="projectType1FundSource">
                        <el-input v-model="form.projectType1FundSource" placeholder="国拨/建设单位承担/自筹"></el-input>
                      </el-form-item></el-col>
                    <el-col :span="12">
                      <el-form-item label="项目负责人" prop="projectType1ProjectManger">
                        <el-input v-model="form.projectType1ProjectManger" placeholder="项目负责人"></el-input>
                      </el-form-item></el-col>
                  </el-row>
                </template>

                <template v-if="currentFormSetting.isProjectType2">
                  <el-form-item label="安防工程类型" prop="projectType2">
                    <el-checkbox-group v-model="form.projectType2">
                      <el-checkbox :label="item.value" :key="item.value" v-for="item in projectType2Dic">{{ item.label
                      }}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>

                  <el-form-item label="工程主要构成" prop="projectType2Compose">
                    <el-checkbox-group v-model="form.projectType2Compose">
                      <el-checkbox :label="item.value" :key="item.value" v-for="item in projectType2ComposeDic">{{
                        item.label }}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>

                  <el-form-item label="安全防护工程方案名称" prop="planName">
                    <el-input v-model="form.planName" placeholder="安全防护工程方案名称"></el-input>
                  </el-form-item>

                  <el-form-item label="工程保护对象及范围" prop="projectType2Area">
                    <el-input v-model="form.projectType2Area" placeholder="工程保护对象及范围"></el-input>
                  </el-form-item>
                  <el-form-item label="工程概算（万元）" prop="projectType2Amount">
                    <el-input v-model="form.projectType2Amount" placeholder="工程概算"></el-input>
                  </el-form-item>
                  <el-form-item label="经费来源" prop="projectType2FundSource">
                    <el-input v-model="form.projectType2FundSource" placeholder="国拨/建设单位承担/自筹"></el-input>
                  </el-form-item>
                </template>

                <el-form-item label="方案编制人" prop="planWriter">
                  <el-input v-model="form.planWriter" placeholder="请填写方案编制单位名称或个人姓名"></el-input>
                </el-form-item>
                <el-form-item label="申报主体" prop="applyUnit">
                  <el-input v-model="form.applyUnit" placeholder="申报主体(文物保护单位管理机构或所有人)"></el-input>
                </el-form-item>
                <el-form-item label="其他说明" prop="otherDesc">
                  <el-input v-model="form.otherDesc" placeholder="不超过2000个汉字" type="textarea" :rows="5"></el-input>
                </el-form-item>
              </el-collapse-item>
              <el-collapse-item name="3" title="申请材料">
                <el-form-item :label="i.label" v-for="i in currentFormSetting.attach" :key="i.id"
                  :prop="`attachList-${i.id}`" :rules="attachRules(i)">
                  <FileuploadListV1 v-model:files="form.attachList" :fileGroup="i.id.toString()" :accpet="i.ext"
                    urlKey="filePath" nameKey="fileName" :limit="i.limit"
                    @upload="() => refForm.clearValidate(`attachList-${i.id}`)">
                    <template #tip v-if="i.tip">
                      <div class="el-upload__tip">
                        {{ i.tip }}
                      </div>
                    </template>
                  </FileuploadListV1>
                </el-form-item>
              </el-collapse-item>
            </el-collapse>
          </el-form>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="操作记录" name="logs" :disabled="!logs || logs.length == 0">
      <LogHistory :data="logs"></LogHistory>
    </el-tab-pane>
  </el-tabs>

  <div class="pageaa-footer">
    <div class="button">
      <el-button icon="el-icon-download" @click="openWindowWithToken(`/api/thingApply/downloadAll/${propsId}`)"
        type="primary" v-if="propsId > 0">打包下载</el-button>

      <el-button icon="el-icon-back" @click="$router.push('/ReportAndReview/Things')">返回</el-button>

    </div>
  </div>
</template>

<script lang="ts" >
import { openWindowWithToken } from "@/utils/util.js";
import FileuploadListV1 from "@/components/fileuploadListV1.vue";
import SelectUnit from "@/views/heritageUnit/selectUnit.vue";
import { isNumber, isEmpty } from "@/utils/validate";
import { useThingApply, useQueryActionHistory } from "@/network/thingApply";
import { useSelectItemList, things, attachSetting } from "@/network/lookUp.ts";
import roles from "@/types/roles.ts";
import { useCheckDataChange } from "@/network/common.ts";
import { ElMessageBox, ElMessage } from "element-plus";
import { useCityRelTown } from "@/network/chinaArea";
import {
  getCurrentInstance,
  defineEmit,
  defineProps,
  onMounted,
  computed,
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  toRef,
  nextTick
} from "vue";

import LogHistory from "@/views/shared/logHistory.vue";
import { useRouter } from "vue-router";
export default defineComponent({
  components: {
    FileuploadListV1,
    SelectUnit,
    LogHistory,
  },
  emits: ["update:id"],
  props: ["id", "createCode"],
  setup(props, { emit }) {


    const refForm = ref(null);
    const router = useRouter();


    const user: any = inject("user");
    const title = ref("选择审批事项");

    const selectUnitSetting = reactive({
      visible: false,
      disabled: false,
      heritage_Name: "",
    });
    const selectThings = ref(null);
    const timeLimitTips = ref("");
    const tabActiveName = ref("submit");
    const collapseAcitveName = ref(["0", "1", "2", "3"]);
    const projectType1Dic = [
      { label: "修缮工程", value: "1" },
      { label: "展示工程", value: "2" },
      { label: "迁建工程", value: "3" },
      { label: "抢险加固工程", value: "4" },
      { label: "其他保护性设施建设工程", value: "5" },
    ];
    const projectType2Dic = [
      { label: "安防工程", value: "1" },
      { label: "消防工程", value: "2" },
      { label: "防雷工程", value: "3" },
    ];
    const projectType2ComposeDic = [
      { label: "入侵报警系统", value: "1" },
      { label: "视频监控系统", value: "2" },
      { label: "出入口控制系统", value: "3" },
      { label: "电子巡更与通信系统", value: "4" },
      { label: "防爆安全监测系统", value: "5" },
      { label: "实体防护和人员防护系统", value: "6" },
      { label: "其它", value: "7" },
    ];



    const heritageClassDic = useSelectItemList("HeritageClass");

    // const thingsRowSpanSet = things.reduce((group, item) => {
    //   const key = item.code;
    //   group[key] = group[key] ?? [];
    //   group[key].push(item);
    //   return group;
    // },{});
    // console.log(thingsRowSpanSet,things);

    const formSetting: any = [
      {
        key: "56004",
        attach: [1, 2, 3, 4, 5, 6],
      },

      {
        key: "56005",
        attach: [
          {
            id: 1,
            required: false,
          },
          2,
          3,
          4,
          5,
          6,
        ],
      },

      // {
      //   key: "56006",
      //   attach: [
      //     1,
      //     7,
      //     3,
      //     {
      //       id: 4,
      //       required: false,
      //     },
      //     5,
      //     6,
      //   ],
      // },
      {
        key: "56010",
        attach: [
          {
            id: 1,
            required: false,
          },
          7,
          3,
          {
            id: 4,
            required: false,
          },
          5,
          6,
        ],
      },
      {
        key: "56012",
        attach: [
          12,
          {
            id: 1,
            required: false,
          },
          2,
          3,
          4,
          5,
          6,
        ],
      },
      {
        key: "56014-1",
        attach: [
          12,
          {
            id: 1,
            required: false,
          },
          9,
          8,
          {
            id: 4,
            required: false,
          },
          5,
          6,
        ],
        isProjectType1: true,
      },
      {
        key: "56014-2",
        attach: [
          {
            id: 1,
            required: false,
          },
          10,
          12,
          11,
          6,
        ],
        isProjectType2: true,
      },
      {
        key: "56022",
        attach: [
          1,
          {
            id: 11,
            required: true,
          },
          6,
        ],
      },
    ];

    const buildFormSetting = () => {
      formSetting.forEach((p) => {
        p.attach = p.attach.map((d) => {
          // console.log(d);
          let i = attachSetting.find(
            (e) => e.id == (typeof d == "number" ? d : d.id)
          );
          return typeof d == "number" ? i : Object.assign({}, i, d);
        });
      });

      //console.log(formSetting);
    };

    buildFormSetting();

    const rules = {
      applyFileTitle: [
        { required: true, message: "请输入申请文件标题！", trigger: "blur" },
      ],
      applyPerson: [
        { required: true, message: "请输入申请单位！", trigger: "blur" },
      ],
      doPerson: [{ required: true, message: "请输入经办人！", trigger: "blur" }],
      concactPhone: [
        { required: true, message: "请输入联系电话！", trigger: "blur" },
      ],
      projectName: [
        { required: true, message: "请输入项目名称！", trigger: "blur" },
      ],
      heritageUnitName: [
        { required: true, message: "请填写或选择文保单位！", trigger: "change" },
      ],
      // heritageUnitId: [
      //   { required: true, message: "请选择文保单位！", trigger: "change" },
      // ],
      heritageClass: [
        { required: true, message: "请选择文保单位类型！", trigger: "change" },
      ],
      heritageLevel: [
        { required: true, message: "请选择文保单位级别！", trigger: "change" },
      ],
      // provId: [
      //   {
      //     required: true, message: "请选择完整地区！", trigger: "change",
      //     validator: (rule, value, callback) => {
      //       if (!form.provId || !form.cityId || !form.countryId) {
      //         return callback(Error(rule.message));
      //       }
      //       callback();
      //     },
      //   },
      // ],
      isRevolution: [
        { required: true, message: "请选择是否革命文物！", trigger: "change" },
      ],
      isWorldHeritage: [
        { required: true, message: "请选择是否世界遗产！", trigger: "change" },
      ],
      planName: [{ required: true, message: "请输入方案名称！", trigger: "blur" }],
      planWriter: [
        { required: true, message: "请输入方案编制人！", trigger: "blur" },
      ],
      applyUnit: [
        {
          required: true,
          message: "请输入申报主体(文物保护单位管理机构或所有人)！",
          trigger: "blur",
        },
      ],
      projectType1ObjName: [
        { required: true, message: "请填写工程对象名称！", trigger: "blur" },
      ],
      projectType1: [
        { required: true, message: "请选择保护工程类型！", trigger: "change" },
      ],
      projectType2: [
        { required: true, message: "请选择安防工程类型！", trigger: "change" },
      ],
      projectType1FundSource: [
        { required: true, message: "请填写经费来源", trigger: "blur" },
      ],

      projectType2FundSource: [
        { required: true, message: "请填写经费来源", trigger: "blur" },
      ],
      projectType2Compose: [
        { required: true, message: "请选择工程主要构成！", trigger: "change" },
      ],
      projectType2Area: [
        {
          required: true,
          message: "请输入工程保护对象及范围！",
          trigger: "blur",
        },
      ],
      projectType2Amount: [
        { required: true, message: "请输入工程概算！", trigger: "blur" },
        {
          message: "填报不正确，请填写数字",
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (isEmpty(value) || !isNumber(value)) {
              return callback(Error(rule.message));
            }
            callback();
          },
        },
      ],
      doc_Type: [
        { required: true, message: "请选择批文类型！", trigger: "change" },
      ],
      doc_Year: [
        { required: true, message: "请选择批文年份！", trigger: "change" },
      ],
      doc_Serial_No: [
        { required: true, message: "请输入批文号！", trigger: "blur" },
        {
          message: "填报不正确，请填写数字",
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (isEmpty(value) || !isNumber(value)) {
              return callback(Error(rule.message));
            }
            callback();
          },
        },
      ],
    };

    const currentFormSetting = ref({
      key: null,
      isProjectType1: false,
      isProjectType2: false,
      attach: [],
    });




    const isFileUploading = ref(false);
    //const { visible, id } = toRefs(props.setting as any);
    const propsId = computed<number>({
      get: () => {
        return props.id;
      },
      set(v) {
        emit("update:id");
      },
    });

    const [isLoading, form, find, save, , review] = useThingApply(propsId);
    const { checkChange, setChangedEqual, oldValue } = useCheckDataChange(form);
    const [logs, queryHistory] = useQueryActionHistory(propsId);
    const now = new Date();

    const [dicCity, dicTown] = useCityRelTown(
      toRef(form, "cityId"),
      toRef(form, "countryId")
    );
    const docYearDic = ref([]);
    for (let i = 0; i < 10; i++) {
      docYearDic.value.push((now.getFullYear() - i).toString());
    }
    const docTypeDic = useSelectItemList("OfficialDocType");

    const setThingItem = (applyCode, applyType) => {
      const item = things.find(
        (d) =>
          d.code == applyCode && (applyType == null || d.applyType == applyType)
      );

      tabActiveName.value = "submit";
      selectThings.value = item;
      //console.log(item, applyCode, applyType);
      currentFormSetting.value = formSetting.find(
        (p) =>
          p.key ==
          selectThings.value.code +
          (selectThings.value.applyType ? `-${selectThings.value.applyType}` : "")
      );
      timeLimitTips.value = "本事项承诺办结时限为" + (item && item.timelimit);
      title.value =
        `[${item.code}]${item.thingsName}申请表 ` +
        (selectThings.value.applyType
          ? `- ${selectThings.value.applyTypeName}`
          : "");
      form.applyCode = item.code;
      form.applyType = item.applyType?.toString();
    };

    const queryDocTypeSearch = (queryString: string, cb: any) => {
      const labels = docTypeDic.value.map((p) => ({ value: p.label }));
      //console.log(labels);
      const results = queryString
        ? labels.filter(createFilter(queryString))
        : labels;
      // call callback function to return suggestions
      cb(results);
    };

    const createFilter = (queryString: string) => {
      return (item) => {
        return item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    };

    onMounted(() => {
      const { proxy } = getCurrentInstance();
      setTimeout(() => {

        console.log(proxy.$refs);
        console.log(refForm, refForm.value);
      }, 1);

      find().then((p) => {
        if (propsId.value > 0) {
          nextTick(() => {
            setTimeout(() => refForm.value.clearValidate(), 1);
          })


          //selectUnitSetting.heritage_Name = p.heritageUnitName;
          setThingItem(p.applyCode, p.applyType);
          queryHistory();
        } else {
          const codeArr = props.createCode.split("-");
          setThingItem(codeArr[0], codeArr.length > 1 ? codeArr[1] : null);
        }
        setTimeout(() => {
          setChangedEqual();
        }, 1);

        // setTimeout(() => {
        //   console.log(oldValue, form, checkChange());
        // }, 5000)
      });
    });

    const attachRules = (i) => [
      {
        required: i.required,
        message: `请上传附件！`,
        validator: (rule, value, callback) => {
          if (i.required) {
            if (
              form.attachList.filter((p) => p.type == i.id.toString()).length == 0
            ) {
              return callback(Error(rule.message));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];

    const selectUnitChange = (item) => {
      form.heritageClass = item.heritageClass_ID;
      form.heritageLevel = item.heritageLevel_Code;
      form.heritageUnitName = item.heritage_Name;

      selectUnitSetting.heritage_Name = "";
    };

    const saveData = async () => {
      await save();
      setChangedEqual();
      if (propsId.value < 1) {
        propsId.value = form.id;
        router.push({
          name: "ReportAndReviewThingsManage",
          params: { id: form.id },
        });
      }

      //console.log("test");
    };
    const doSave = async (submit = false) => {
      if (submit) {
        //console.log(checkChange());
        if (checkChange()) {
          try {
            await ElMessageBox.confirm("检查到数据有修改，是否保存后再提交！？");
          } catch {
            ElMessage.info({
              message: "取消提交，请检查表单数据或保存后可再次提交",
              duration: 6000,
              type: "info",
            });
            return;
          }
          await saveData();
        }
        refForm.value.validate((valid) => {
          if (valid) {
            //执行提交
            ElMessageBox.confirm("确认提交审核吗？")
              .then(() => {
                review(form.id, { result: true }).then(() => {
                  router.push(`/ReportAndReview/Things`);
                });
              })
              .catch(() => {
                ElMessage.info({
                  message: "取消提交",
                  duration: 6000,
                  type: "info",
                });
              });
          } else {
            ElMessage.error("表单验证失败！请检查各表单项中的红色提示错误部分！");
            return;
          }
        });
      } else {
        await saveData();
      }
    };

    const onReviewSubmit = (result) => {
      //console.log(result);

      saveData().then(() => review(form.id, result).then((p) => {
        router.push(`/ReportAndReview/Things`);
      }));
    };


    return {
      roles,
      logs,
      isLoading,
      form,
      propsId,
      refForm,
      user,
      title,
      selectUnitSetting,
      selectThings,
      timeLimitTips,
      tabActiveName,
      collapseAcitveName,
      projectType1Dic,
      projectType2Dic,
      projectType2ComposeDic,
      heritageClassDic,
      formSetting,
      rules,
      currentFormSetting,
      isFileUploading,
      docTypeDic,
      dicCity,
      dicTown,
      docYearDic,
      doSave,
      onReviewSubmit,
      selectUnitChange,
      attachRules,
      queryDocTypeSearch,
    }
  }

})
</script>

<style scoped></style>
